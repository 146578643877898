import React from "react";
import preloadImage from 'components/pages/gallery/images/preloader.jpg'
import _lang from 'lodash/lang'
import _array from 'lodash/array'

const OhzImage = ( props ) => {
    
    const { alt = [], style = [] } = props;
    
    let imageAlt = !Array.isArray( alt ) ? [ alt ] : alt;

    imageAlt = [
        'Ohz5',
        'Ohz',
        'Oosthuizen 5',
        'v.v. Oosthuizen 5',
        ...imageAlt
    ];
    
    const src = _lang.isString( props.src ) ? props.src : _array.first( props.src );

    return (
        <img
            {...props}
            src={ src }
            alt={ imageAlt.join(', ') }
            style={ style }
        />
    )
}

export const OhzImageLoader = ({ style = {} }) => {
    return (
        <OhzImage
            src={ preloadImage }
            style={ style }
        />
    )
}

export const OhzImageWithPreload = ({ src, alt = '', style = {} }) => {
    
    return (
        <OhzImage
            src={ [ src, src, src, preloadImage] }
            alt={ alt }
            style={ style }
            loader={ <OhzImageLoader style={ style } /> }
        />
    )
}

export default OhzImage
